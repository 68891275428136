import { Experiment, FlowApi } from '../../types';

export const isCustomProfileEnabled = ({
  experiments,
  controllerConfig,
}: FlowApi) => {
  const { booleans } = controllerConfig.config.style.styleParams;
  const isSettingEnabled = booleans?.customProfileEnabled ?? false;
  const isExperimentEnabled = experiments.enabled(
    Experiment.ViewerCustomProfile,
  );

  return isSettingEnabled && isExperimentEnabled;
};

export const withLocaleHeadersFactory =
  (flowAPI: FlowApi) =>
  <T>(requestOptions: T) => {
    if (flowAPI.environment.multilingual.isEnabled) {
      const currentLanguage =
        flowAPI.environment.multilingual.siteLanguages.find(
          (lang) =>
            lang.languageCode ===
            flowAPI.environment.multilingual.currentLanguage,
        );
      if (currentLanguage) {
        const wixLinguistHeader = {
          'x-wix-linguist': `${currentLanguage.languageCode}|${currentLanguage.locale}|${currentLanguage.isPrimaryLanguage}|${flowAPI.environment.appDefinitionId}`,
        };

        return {
          ...requestOptions,
          headers: { ...requestOptions, ...wixLinguistHeader },
        };
      }
    }

    return requestOptions;
  };
