import { bindActionCreators, configureStore } from '@reduxjs/toolkit';

import { Extra } from '../types';
import {
  memberThunk,
  fieldsThunk,
  fieldsSlice,
  memberSlice,
  uiThunk,
  uiSlice,
} from './slices';
import { rootReducer, RootState } from './rootReducer';

export const createStore = (
  extraArgument: Extra,
  initialState: Partial<RootState>,
) =>
  configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          isSerializable: () => true,
        },
        thunk: { extraArgument },
      }),
  });

export type Store = ReturnType<typeof createStore>;

export const getActionHandlers = (store: Store) => ({
  memberHandlers: bindActionCreators(
    { ...memberThunk, ...memberSlice.actions },
    store.dispatch,
  ),
  fieldsHandlers: bindActionCreators(
    { ...fieldsThunk, ...fieldsSlice.actions },
    store.dispatch,
  ),
  uiHandlers: bindActionCreators(
    { ...uiThunk, ...uiSlice.actions },
    store.dispatch,
  ),
});
