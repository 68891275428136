import { combineReducers } from '@reduxjs/toolkit';

import {
  fieldsSlice,
  memberSlice,
  siteSlice,
  uiSlice,
  localeSlice,
} from './slices';

export const rootReducer = combineReducers({
  member: memberSlice.reducer,
  fields: fieldsSlice.reducer,
  site: siteSlice.reducer,
  ui: uiSlice.reducer,
  locale: localeSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
