import { dsl } from '@wix/yoshi-serverless/wrap';

export const getCountriesData = dsl({
          functionName: 'getCountriesData',
          fileName: 'server/account.api',
        });

export const getAccountData = dsl({
          functionName: 'getAccountData',
          fileName: 'server/account.api',
        });

export const isSlugAvailable = dsl({
          functionName: 'isSlugAvailable',
          fileName: 'server/account.api',
        });

export const updateFields = dsl({
          functionName: 'updateFields',
          fileName: 'server/account.api',
        });

export const updateSlug = dsl({
          functionName: 'updateSlug',
          fileName: 'server/account.api',
        });

export const joinCommunity = dsl({
          functionName: 'joinCommunity',
          fileName: 'server/account.api',
        });

export const leaveCommunity = dsl({
          functionName: 'leaveCommunity',
          fileName: 'server/account.api',
        });