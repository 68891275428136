import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type State = {
  baseUrl: string;
};

export const siteSlice = createSlice({
  name: 'site',
  initialState: {
    baseUrl: '',
    isSocial: false,
    isCustomProfileEnabled: false,
  },
  reducers: {
    setBaseUrl(state, { payload }: PayloadAction<string>) {
      state.baseUrl = payload;
    },
  },
});
