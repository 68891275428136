import { Field, PublicInfoFieldId } from '../../../types';
import { ProfileInfo } from '../../../viewer.app';

export const hasPublicInfoChanged = (
  initialPublicInfo: ProfileInfo,
  updatedPublicInfo: ProfileInfo,
) =>
  updatedPublicInfo.name !== initialPublicInfo.name ||
  updatedPublicInfo.title !== initialPublicInfo.title;

export const getPublicInfo = (fields: Field[]) => {
  const nameFieldValue = fields.find(
    ({ id }) => id === PublicInfoFieldId.DISPLAY_NAME,
  )?.value as string;
  const titleFieldValue = fields.find(
    ({ id }) => id === PublicInfoFieldId.TITLE,
  )?.value as string | undefined;

  return {
    name: nameFieldValue,
    title: titleFieldValue,
  };
};
