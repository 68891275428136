import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Member, ThunkApiConfig, Toast } from '../../../types';
import { isSlugAvailable } from '../../../server/account.api';
import {
  makeProfilePrivate,
  makeProfilePublic,
  updateSlug,
} from '../member/thunk';
import { fieldsThunk } from '../fields/slice';
import { ServerErrorCode } from '../../../types/server';

type State = {
  toast: {
    isVisible: boolean;
    type: Toast;
  };
  slug: {
    isLoading: boolean;
    isAvailable: boolean;
  };
};

const querySlugAvailability = createAsyncThunk<
  boolean,
  Member['profile']['slug'],
  ThunkApiConfig
>('ui/querySlugAvailability', async (slug, { extra }) => {
  const { flowAPI } = extra;
  const { data } = await flowAPI.httpClient.request(isSlugAvailable(slug));
  return data;
});

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    toast: {
      isVisible: false,
      type: Toast.NONE,
    },
    slug: {
      isLoading: false,
      isAvailable: true,
    },
    dropdownOptions: {
      country: [],
      state: [],
    },
  } as State,
  reducers: {
    setToast(state, action: PayloadAction<Toast>) {
      state.toast.type = action.payload;
    },
    hideToast(state) {
      state.toast.isVisible = false;
    },
    setSlugAvailability(state, action: PayloadAction<boolean>) {
      state.slug.isAvailable = action.payload;
    },
    setSlugLoading(state, action: PayloadAction<boolean>) {
      state.slug.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(querySlugAvailability.pending, (state) => {
        state.slug.isLoading = true;
      })
      .addCase(querySlugAvailability.fulfilled, (state, { payload }) => {
        state.slug.isAvailable = payload;
        state.slug.isLoading = false;
      })
      .addCase(querySlugAvailability.rejected, (state) => {
        state.slug.isAvailable = true;
        state.slug.isLoading = false;
      })
      .addCase(updateSlug.fulfilled, (state) => {
        state.toast.type = Toast.PROFILE_URL_UPDATE_SUCCESS;
        state.toast.isVisible = true;
      })
      .addCase(updateSlug.rejected, (state) => {
        state.toast.type = Toast.PROFILE_URL_UPDATE_ERROR;
        state.toast.isVisible = true;
      })
      .addCase(fieldsThunk.updateFields.fulfilled, (state) => {
        state.toast.type = Toast.FORM_UPDATE_SUCCESS;
        state.toast.isVisible = true;
      })
      .addCase(fieldsThunk.updateFields.rejected, (state, { payload }) => {
        switch (payload as ServerErrorCode) {
          case ServerErrorCode.INTERNAL:
            state.toast.type = Toast.FORM_UPDATE_ERROR_INTERNAL;
            break;
          case ServerErrorCode.UNAUTHENTICATED:
            state.toast.type = Toast.FORM_UPDATE_ERROR_UNAUTHENTICATED;
            break;
          case ServerErrorCode.UNAVAILABLE:
            state.toast.type = Toast.FORM_UPDATE_ERROR_UNAVAILABLE;
            break;
          case ServerErrorCode.PERMISSION_DENIED:
            state.toast.type = Toast.FORM_UPDATE_ERROR_PERMISSION_DENIED;
            break;
          case ServerErrorCode.INVALID_ARGUMENT:
            state.toast.type = Toast.FORM_UPDATE_ERROR_INVALID_ARGUMENT;
            break;
          case ServerErrorCode.INVALID_CUSTOM_FIELD_URL:
            state.toast.type = Toast.FORM_UPDATE_ERROR_INVALID_URL;
            break;
          default:
            state.toast.type = Toast.FORM_UPDATE_ERROR_UNKNOWN;
            break;
        }
        state.toast.isVisible = true;
      })
      .addCase(makeProfilePublic.fulfilled, (state) => {
        state.toast.type = Toast.PROFILE_PRIVACY_CHANGE_SUCCESS;
        state.toast.isVisible = true;
      })
      .addCase(makeProfilePublic.rejected, (state) => {
        state.toast.type = Toast.PROFILE_PRIVACY_CHANGE_ERROR;
        state.toast.isVisible = true;
      })
      .addCase(makeProfilePrivate.fulfilled, (state) => {
        state.toast.type = Toast.PROFILE_PRIVACY_CHANGE_SUCCESS;
        state.toast.isVisible = true;
      })
      .addCase(makeProfilePrivate.rejected, (state) => {
        state.toast.type = Toast.PROFILE_PRIVACY_CHANGE_ERROR;
        state.toast.isVisible = true;
      });
  },
});

export const uiThunk = { querySlugAvailability };
