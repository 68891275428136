import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { createStore, getActionHandlers, Store } from '../../store';
import { ControllerProps, FlowApi, WixCodeApi } from '../../types';
import { getAccountData } from '../../server/account.api';
import { AccountData } from '../../types/server';
import { localeThunk } from '../../store/slices';
import { ProfileInfoChangeSubject } from '../../viewer.app';
import {
  isCustomProfileEnabled,
  withLocaleHeadersFactory,
} from './controllerUtils';

const getControllerProps = (store: Store) => {
  const state = store.getState();
  const actionHandlers = getActionHandlers(store);
  const props: ControllerProps = {
    ...state,
    ...actionHandlers,
  };

  return props;
};

const getInitialState = async (wixCodeApi: WixCodeApi, flowAPI: FlowApi) => {
  const withLocaleHeaders = withLocaleHeadersFactory(flowAPI);
  const { data } = await flowAPI.httpClient.request<AccountData>(
    withLocaleHeaders(
      getAccountData({
        isCustomProfileEnabled: isCustomProfileEnabled(flowAPI),
      }),
    ),
  );

  const { member, fields, site } = data;

  return {
    fields,
    member,
    site: {
      baseUrl: wixCodeApi.location.baseUrl,
      isSocial: site?.isSocial ?? false,
      isCustomProfileEnabled: isCustomProfileEnabled(flowAPI),
    },
  };
};

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig: { setProps, wixCodeApi },
  appData,
}) => {
  const profileInfoChangeSubject = appData?.profileInfoChangeSubject as
    | ProfileInfoChangeSubject
    | undefined;

  return {
    async pageReady() {
      if (wixCodeApi.user.currentUser.loggedIn === false) {
        flowAPI.sentry.captureMessage('User not logged in');
        return;
      }

      const store = createStore(
        {
          flowAPI,
          wixCodeApi,
          profileInfoChangeSubject,
        },
        await getInitialState(wixCodeApi, flowAPI),
      );
      store.subscribe(() => setProps({ ...store.getState() }));

      const props = getControllerProps(store);
      setProps(props);
      store.dispatch(localeThunk.fetchLocaleData());
    },
    onBeforeUnLoad() {
      profileInfoChangeSubject?.unregisterObservers();
    },
  };
};

export default createController;
