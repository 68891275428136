import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Field, ThunkApiConfig } from '../../../types';
import { AccountData, ServerErrorCode } from '../../../types/server';
import { updateFields as updateFieldsRequest } from '../../../server/account.api';
import { getPublicInfo, hasPublicInfoChanged } from './utils';
import { Interactions } from '../../../constants/interactions';

type State = Field[];

interface UpdateFieldsProps {
  fields: Field[];
  changedPrivacyFields: Field[];
}

const updateFields = createAsyncThunk<
  AccountData,
  UpdateFieldsProps,
  ThunkApiConfig
>(
  'fields/update',
  async (
    { fields: currentFields, changedPrivacyFields },
    { getState, extra: { flowAPI, profileInfoChangeSubject }, rejectWithValue },
  ) => {
    const state = getState();
    const { member } = state;
    const initialFields = state.fields;
    const changedPrivacyFieldsIds = changedPrivacyFields.map(
      (field) => field.id,
    );

    try {
      flowAPI.fedops.interactionStarted(Interactions.FieldUpdate);

      const { data } = await flowAPI.httpClient.request(
        updateFieldsRequest({
          member,
          fields: currentFields,
          changedPrivacyFieldsIds,
        }),
      );
      if ('error' in data) {
        return rejectWithValue(data.error);
      }

      const initialProfileInfo = getPublicInfo(initialFields);
      const currentProfileInfo = getPublicInfo(currentFields);

      if (hasPublicInfoChanged(initialProfileInfo, currentProfileInfo)) {
        profileInfoChangeSubject?.notifyObservers(currentProfileInfo);
      }

      flowAPI.fedops.interactionEnded(Interactions.FieldUpdate);

      return data;
    } catch (error) {
      return rejectWithValue(ServerErrorCode.INTERNAL);
    }
  },
);

export const fieldsSlice = createSlice({
  name: 'fields',
  initialState: [] as State,
  reducers: {
    setFields(state, action: PayloadAction<Field[]>) {
      state = action.payload;
    },
    setField(state, action: PayloadAction<Field>) {
      state = state.map((field) =>
        field.id === action.payload.id ? action.payload : field,
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(updateFields.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }
      const updatedFields = payload.fields;

      state.forEach((currentField) => {
        const updatedField = updatedFields.find(
          ({ id }) => id === currentField.id,
        );

        currentField.value = updatedField?.value ?? currentField.value;
        currentField.privacy = updatedField?.privacy ?? currentField.privacy;
      });
    });
  },
});

export const fieldsThunk = { updateFields };
